.logoLogin {
    max-width: 400px!important;
    height: auto;
}

@media only screen and (max-width: 426px) {
    .logoLogin {
        max-width: 320px!important;
        height: auto;
    }  
}

@media only screen and (max-width: 376px) {
    .logoLogin {
        max-width: 280px!important;
        height: auto;
    }  
}