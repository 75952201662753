.divAdmin {
    background-color: lightgray;
    border: 5px solid black;
    border-radius: 15px;
    margin: 0 auto; 
    font-size: 45px;
    font-weight: bold;
}


/*Boton normal*/

.boton{
    font-size: 23px;
    font-weight: bold;
    color: white;
    background-color: #8a7342;
    border: 2px solid black;
}

.boton:hover {
    font-size: 23px;
    font-weight: bold;
    color: black;
    background-color: #b49b64;
    border: 2px solid black;
    box-shadow: 0 -3px 0 0 rgb(24 24 24 / 15%) inset, 0 3px 10px rgb(0 0 0 / 30%);
}

.boton:focus {
    font-size: 23px;
    font-weight: bold;
    color: black;
    background-color: #b49b64;
    border: 2px solid black;
    box-shadow: 0 -3px 0 0 rgb(24 24 24 / 15%) inset, 0 3px 10px rgb(0 0 0 / 30%);
}

/*Boton rojo*/
.boton-danger{
    font-size: 23px;
    font-weight: bold;
    color: white;
    background-color: #ff1a1a;
    border: 2px solid black;
}

.boton-danger:hover {
    font-size: 23px;
    font-weight: bold;
    color: black;
    background-color: #ff6666;
    border: 2px solid black;
    box-shadow: 0 -3px 0 0 rgb(24 24 24 / 15%) inset, 0 3px 10px rgb(0 0 0 / 30%);
}

.boton-danger:focus {
    font-size: 23px;
    font-weight: bold;
    color: black;
    background-color: #ff6666;
    border: 2px solid black;
    box-shadow: 0 -3px 0 0 rgb(24 24 24 / 15%) inset, 0 3px 10px rgb(0 0 0 / 30%);
}

.graficos {
    width: auto;
    height: 500px;
    background: rgba(255,255,255,0.3);
    padding: 20px;
}
