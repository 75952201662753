body {
    background-color: #bda675;
    
}

.divAdmin {
    background-color: lightgray;
    border: 5px solid black;
    border-radius: 15px;
    margin: 0 auto; 
    font-size: 45px;
    font-weight: bold;
}

.boton{
    font-size: 23px;
    font-weight: bold;
    color: white;
    background-color: #8a7342;
    border: 2px solid black;
}

.boton:hover {
    font-size: 23px;
    font-weight: bold;
    color: black;
    background-color: #b49b64;
    border: 2px solid black;
    box-shadow: 0 -3px 0 0 rgb(24 24 24 / 15%) inset, 0 3px 10px rgb(0 0 0 / 30%);
}

.boton:focus {
    font-size: 23px;
    font-weight: bold;
    color: black;
    background-color: #b49b64;
    border: 2px solid black;
    box-shadow: 0 -3px 0 0 rgb(24 24 24 / 15%) inset, 0 3px 10px rgb(0 0 0 / 30%);
}

h1 {
    font-size: 55px!important;
    font-weight: bold;
}

.email {
    font-size: 45px!important;
    font-weight: bold;
    max-width: 90%;
    overflow: hidden;
}

h2 {
    font-size: 45px!important;
    font-weight: bold;
}

.right {
    margin-left: auto !important;
    background-color: #212529;
}

.scroll {
    overflow: scroll;
}

.headerNavbar{
    height: 100px;
    color: white!important;
}

.nav-link{
    font-size: 20px!important;
    font-weight: bold;
    color: white!important;

}

.logo {
    width: auto;
    height: 100px;
}

@media only screen and (max-width: 991px) {
    .headerNavbar {
        height: 165px;
        color: white!important;
    }
}

@media only screen and (max-width: 328px) {
    .headerNavbar {
        height: 165px;
        color: white!important;
    }

    .logo{
        width: auto;
        height: 90px;
    }
}  

@media only screen and (max-width: 359px) {
    h1 {
        font-size: 45px!important;
        font-weight: bold;
    }
    
    .email {
        font-size: 35px!important;
        font-weight: bold;
        max-width: 90%;
        overflow: hidden;
    }
    
    h2 {
        font-size: 35px!important;
        font-weight: bold;
    }

    .titulosAdmin{
        font-size: 35px;
    }
}




