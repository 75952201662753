.ag-header-cell-label {
    text-align: center;
}

.ag-cell, .ag-header-cell-label {
  display: flex!important;
  align-items: center;
  text-align: center;
    font-size: 25px;
  justify-content: center; /* align horizontal */
  align-items: center;
  vertical-align: middle;
}

.ag-cell-left{
  text-align: left;
    font-size: 25px;
  justify-content: left; /* align horizontal */
  align-items: left;
  vertical-align: middle;
}

.ag-root-wrapper {
  border-radius: 15px;
}

.ag-theme-alpine {
  border: 5px solid black;
  border-radius: 15px;
  font-size: 25;
}

.actius{
  width: 75%;
}

.tipus{
  width: 52%;
}

.fotografies, .usuaris {
  width: 60%;
}